<template>
  <div class="content-info">
    <div class="content">
      <div class="content-block">
        <div class="item-left">
          <div class="item-block item-block-gray">
            <img src="@/assets/contact-service.png" alt="客服热线" />
            <div class="item-title size-14">客服热线</div>
            <div class="item-info size-12">请拨：<span class="info-blue bold-title size-14">18126461599</span></div>
          </div>
          <div class="item-block item-block-sgray">
            <img src="@/assets/contact-telphone.png" alt="座机联系" />
            <div class="item-title size-14">座机联系</div>
            <div class="item-info size-12">请拨：<span class="info-blue bold-title size-14">0755-86001599</span></div>
          </div>
          <div class="item-block item-block-gray">
            <img src="@/assets/contact-service.png" alt="人才计划" />
            <div class="item-title size-14">人才计划</div>
            <div class="item-info size-12">求职：<span class="info-blue bold-title size-14">0755-86001728</span></div>
          </div>
        </div>

        <div class="focus-item">
          <div class="focus-item-title size-14">关注我们</div>
          <img src="@/assets/code.png" alt="关注我们" />
        </div>
        <div style="clear:both;"></div>
      </div>
    </div>

     <div class="info">
        <div class="info-right info-right-gray">
          <div class="info-content size-12">  
            <div class="info-title size-18 bold-title">
              <img src="@/assets/logo.png" alt="易宝通">
              <span class="info-title-line">湖北易宝通智能科技有限公司</span>
            </div>
           <div class="info-title-blue bold-title ">湖北易宝通智能科技有限公司：</div>
            湖北省黄冈市浠水县散花镇速易宝科技工业园
          </div>
        </div>
        <div class="info-left-l">
          <baidu-map  @ready="handler" class="bm-view" :scroll-wheel-zoom='true'></baidu-map>
        </div>
        <div style="clear:both;"></div>
      </div>

        <!-- <baidu-map zoom="17" @ready="handler" class="bm-view" :scroll-wheel-zoom='true'> -->
            <!-- scroll-wheel-zoom：能否通过滚轮缩放地图 -->
        <!-- </baidu-map> -->
  </div>
</template>

<script>
export default {
  name: 'AboutContact',
  data() {
    return {
    }
  },
  methods: {
     handler({ BMap, map }) {
        // 标注点的经纬度
        // let point = new BMap.Point(123.403032, 41.799835)
        // map.centerAndZoom(point, 13)

        // // 创建标注
        // let marker = new BMap.Marker(point)

        // // 将标注添加到地图中
        // map.addOverlay(marker)
        // let circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
        // map.addOverlay(circle)

        let sContent = "湖北省黄冈市浠水县散花镇速易宝科技工业园";
        // var map = new BMap.Map("allmap2");
        // 12817106.715,3513937.95
        let point = new BMap.Point(115.136777,30.249262);
        let marker = new BMap.Marker(point);
        let infoWindow = new BMap.InfoWindow(sContent); // 创建信息窗口对象
        map.centerAndZoom(point, 14);
        map.addOverlay(marker);
        map.enableScrollWheelZoom(); //启用滚轮放大缩小，默认禁用
        map.enableContinuousZoom(); //启用地图惯性拖拽，默认禁用
        marker.openInfoWindow(infoWindow)
        marker.addEventListener("click", function() {
            this.openInfoWindow(infoWindow);
        });
        
    },
  },
}
</script>

<style scoped>
  .bm-view {
    height:300px;
  }

  .content {
    padding:0 10%;
  }

  .content-block {
    margin:40px 0;
  }

  .item-left {
    width:72%;
    float: left;
    margin-right:3%;
  }

  .item-block {
    width:33.333%;
    display: inline-block;
    text-align: center;
  }

  .item-block img {
    margin:14% 0 8%;
  }
  
  .item-title {
    margin-bottom: 12px;
  }

  .item-info {
    padding-bottom: 13%;
  }

  .item-block-gray{
    background-color: #f0f0f0;
  }

   .item-block-sgray{
    background-color: #f8f8f8;
  }

  .info-blue {
    color:#0094de;
  }

  .focus-item {
    float: right;
    border-top: 5px #0094de solid;
    background: #f8f8f8;
    width: 25%;
    padding-bottom:30px;
    text-align: center;
    /* padding: 0 4% 3%; */
  }

  .focus-item-title{
    text-align: center;
    margin: 20px 0;
  }

  .info-title {
    display: flex;
    align-items: center;
  }

  .info-title img {
    width: 26px;
  }

  .info-title-line {
    display: inline-block;
    padding-left:5px;
    margin-left:5px;
    border-left:2px solid #525252;
  }

  .info-left-l {
    width: 50%;
    display: inline-block;
    position: relative;
  }

  @media (max-width: 1024px) {
    .item-left {
      width:100%;
      float: none;
      margin-right:0;
    }

    .focus-item {
      float: none;
      width: 100%;
      padding-bottom:20px;
      margin-top:20px;
      /* padding: 0 4% 3%; */
    }

     .focus-item img {
        width: 30%;
     }

      .info-left-l {
      width:100%;
      display: block;
    }
  }

  @media (max-width: 768px) {
    .content {
      padding:10px;
    }

    .item-block {
      width:100%;
      display: block;
      text-align: center;
    }
  }
</style>